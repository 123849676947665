import { Trans, useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';
import React from 'react';

import { Button, Container, Icon } from '@/components';
import type { SvgProps } from '@/components/Sprite/Icon';

interface SavingsPlatformBlockProps {
  icon: SvgProps['name'];
  title: string;
  description: string;
}

const SavingsPlatformBlock: FC<SavingsPlatformBlockProps> = ({
  title,
  description,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 gap-5 sm:flex-col sm:items-center sm:gap-0">
      <div className="size-max rounded-full bg-blue-50 p-3 sm:p-4">
        <Icon name={icon} className="size-6 text-blue-500 sm:size-8" />
      </div>
      <div>
        <p className="body2-bold text-blue-900 sm:mt-6 sm:text-center">
          {t(title)}
        </p>
        <p className="body3 mt-1 text-grey-800 sm:text-center">
          <Trans
            i18nKey={description}
            t={t}
            components={{
              blueText: (
                <Link
                  href={t('faq_page.question_5.link')}
                  className="text-blue-500"
                />
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
};
export const SavingsPlatform = () => {
  const { t } = useTranslation();

  const BLOCKS: SavingsPlatformBlockProps[] = [
    {
      icon: 'profile',
      title: 'homev2.ptb-savings.block1.title',
      description: 'homev2.ptb-savings.block1.description',
    },
    {
      icon: 'percent',
      title: 'homev2.ptb-savings.block2.title',
      description: 'homev2.ptb-savings.block2.description',
    },
    {
      icon: 'shield-check',
      title: 'homev2.ptb-savings.block3.title',
      description: 'homev2.ptb-savings.block3.description',
    },
  ];

  return (
    <div className="mt-24 flex w-full border-b pb-24">
      <Container className="z-[1] max-w-[948px]">
        <h1 className="text-center">{t('homev2.ptb-savings.title')}</h1>
        <div className="my-12 flex flex-col gap-8 sm:flex-row">
          {BLOCKS.map((block, index) => (
            <SavingsPlatformBlock key={index} {...block} />
          ))}
        </div>
        <div className="flex justify-center">
          <Link href="/multibanking-platform">
            <Button className="w-full px-6 py-[18px] md:w-max" color="blue">
              {t('footer.Get started')}
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};
