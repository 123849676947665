import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import React from 'react';

import { Button, Container } from '@/components';
import product1 from '@/public/assets/home/products-1.png';
import product2 from '@/public/assets/home/products-2.png';

export const HomeProducts = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-24 flex w-full border-b pb-16">
      <Container className="z-[1]">
        <h1 className="text-center">{t('homev2.our-products')}</h1>
        <div className="mt-12 flex flex-col gap-6 md:flex-row">
          <div className="flex flex-1 flex-col items-center rounded-2xl bg-blue-50 px-6 py-8 lg:py-10">
            <Image src={product1} alt="background" width={282} height={240} />
            <p className="h5 mt-6 text-center">
              {t('homev2.product.one.title')}
            </p>
            <ul className="mt-3">
              <li className="body1 flex items-center justify-center gap-2 text-center text-grey-800">
                <span className="inline-block size-[5px] shrink-0 rounded-full bg-grey-800" />
                {t('homev2.product.one.line1')}
              </li>
              <li className="body1 flex items-center justify-center gap-2 text-center text-grey-800">
                <span className="inline-block size-[5px] shrink-0 rounded-full bg-grey-800" />
                {t('homev2.product.one.line2')}
              </li>
              <li className="body1 flex items-center justify-center gap-2 text-center text-grey-800">
                <span className="inline-block size-[5px] shrink-0 rounded-full bg-grey-800" />
                {t('homev2.product.one.line3')}
              </li>
            </ul>
            <Link href="/multibanking-platform" className="mt-8">
              <Button className="w-full px-6 py-[18px] md:w-max" color="blue">
                {t('homev2.product.one.action')}
              </Button>
            </Link>
          </div>
          <div className="flex flex-1 flex-col items-center rounded-2xl bg-blue-50 px-6 py-8 lg:py-10">
            <div className="flex items-center justify-center">
              <Image
                src={product2}
                alt="background"
                width={406}
                // className="h-[130px] w-[208px] md:h-[176px] md:w-[280px]"
                // className="bg-blue-100 px-[30px] py-[15px] sm:px-[34px] sm:py-[17px] md:px-[46px] md:py-[23px] lg:px-[63px] lg:py-[32px]"
              />
            </div>
            <p className="h5 mt-6 text-center">
              {t('homev2.product.two.title')}
            </p>
            <ul className="mt-3">
              <li className="body1 flex items-center justify-center gap-2 text-center text-grey-800">
                <span className="inline-block size-[5px] shrink-0 rounded-full bg-grey-800" />
                {t('homev2.product.two.line1')}
              </li>
              <li className="body1 flex items-center justify-center gap-2 text-center text-grey-800">
                <span className="inline-block  size-[5px] shrink-0 rounded-full bg-grey-800" />
                {t('homev2.product.two.line2')}
              </li>
              <li className="body1 flex items-center justify-center gap-2 text-center text-grey-800">
                <span className="inline-block size-[5px] shrink-0 rounded-full bg-grey-800" />
                {t('homev2.product.two.line3')}
              </li>
            </ul>
            <Link href="/fixed-deposits" className="mt-8">
              <Button
                className="w-full px-6 py-[18px] md:w-max"
                color="secondary"
              >
                {t('homev2.product.two.action')}
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};
