import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';
import React from 'react';

import { Button, Container, Icon } from '@/components';
import EU_BANK from '@/public/assets/home/eu-bank.png';
import PIG from '@/public/assets/home/pig.png';
import SAFE from '@/public/assets/home/safe.png';

interface MoreOnWebsiteBlockProps {
  image: StaticImageData;
  title: string;
  linkTitle: string;
  linkHref: string;
}

const BLOCKS: MoreOnWebsiteBlockProps[] = [
  {
    image: SAFE,
    title: 'homev2.more.block1.title',
    linkTitle: 'homev2.more.block1.description',
    linkHref: '/statistics/maximum-interest-rates-in-different-countries/',
  },
  {
    image: PIG,
    title: 'homev2.more.block2.title',
    linkTitle: 'homev2.more.block2.description',
    linkHref: '/statistics/savings-accounts/',
  },
  {
    image: EU_BANK,
    title: 'homev2.more.block3.title',
    linkTitle: 'homev2.more.block3.description',
    linkHref: '/banks/',
  },
];

const MoreOnWebsiteBlock: FC<MoreOnWebsiteBlockProps> = ({
  title,
  image,
  linkTitle,
  linkHref,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col justify-between gap-8 rounded-2xl bg-blue-50 px-8 pb-8 pt-6">
      <div>
        <Image
          src={image}
          alt={t(title)}
          height={128}
          width={128}
          className="size-[128px]"
        />
        <p className="h6 mt-6 text-blue-900">{t(title)}</p>
      </div>
      <Link href={linkHref}>
        <Button className="w-max px-6 py-[13px]" color="secondary">
          {t(linkTitle)}
          <Icon name="ArrowLight" className="ml-1 mt-[2px]" />
        </Button>
      </Link>
    </div>
  );
};
export const MoreOnWebsite = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-24 flex w-full pb-16">
      <Container className="z-[1]">
        <h1 className="text-center">{t('homev2.more.title')}</h1>
        <div className="my-12 grid grid-cols-1 gap-6 sm:gap-8 md:grid-cols-2 lg:grid-cols-3">
          {BLOCKS.map((block, index) => (
            <MoreOnWebsiteBlock key={index} {...block} />
          ))}
        </div>
      </Container>
    </div>
  );
};
