import { useRouter } from 'next-translate-routes/router';
import { useEffect } from 'react';

import { useApi } from '@/contexts/ApiContext';
import { useCmsContent } from '@/contexts/CmsContentContext';
import { MapDepositsFromServer } from '@/services/supabase';
import { useAuthModalsControl, useCookiesStore } from '@/store';
import { useBestOffersModalStore } from '@/store/bestOffersModalStore';
import type { IBankDepositCard } from '@/types';
import { useShowModal } from '@/usecases/modals';
import { isMobileApp } from '@/utils/isMobileApp';

export const useBestOffersModal = () => {
  const { showBestOffersModal } = useShowModal();
  const api = useApi();
  const { country } = useCmsContent();
  const { isModalShown, setIsModalShown, setProducts, products } =
    useBestOffersModalStore((state) => state);
  const { isOpenModal: isAuthModalOpen } = useAuthModalsControl();
  const { dialogCookies: isCookiesModalOpen } = useCookiesStore(
    (state) => state,
  );
  const router = useRouter();

  const fetchDeposits = async () => {
    try {
      const { data: bank } = await api.getBankRequest(
        router.locale || 'en',
        'lidion-bank-agrkmtmt' as string,
        false,
      );
      const { data: accounts } = await api.getDepositsListRequest('en', {
        page: 1,
        product_types: ['fixed term deposit'],
        is_partner: true,
        countries: [country.alpha2],
        currencies: [country.currency],
        bank_uuid: bank.uuid,
      });
      const preparedAccounts: IBankDepositCard[] = accounts?.map(
        (deposit: any) => MapDepositsFromServer(deposit),
      )!;
      setProducts(preparedAccounts.splice(0, 3));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const element = document.getElementsByTagName('body')[0];
    const handleMouseLeave = () => {
      if (
        products.length > 0 &&
        !isModalShown &&
        !isAuthModalOpen &&
        !isCookiesModalOpen &&
        !isMobileApp()
      ) {
        showBestOffersModal({ products });
        setIsModalShown(true);
      }
    };
    element?.addEventListener('mouseleave', handleMouseLeave);
    return () => element?.removeEventListener('mouseleave', handleMouseLeave);
  }, [products, isModalShown, isAuthModalOpen, isCookiesModalOpen]);

  useEffect(() => {
    if (!products.length) {
      fetchDeposits();
    }
  }, [products.length]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        products.length > 0 &&
        !isModalShown &&
        !isAuthModalOpen &&
        !isCookiesModalOpen &&
        !isMobileApp()
      ) {
        showBestOffersModal({ products });
        setIsModalShown(true);
      }
    }, 10000);
    return () => clearTimeout(timeout);
  }, [products, isModalShown, isAuthModalOpen, isCookiesModalOpen]);
};
