import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import React, { type FC, useEffect } from 'react';

import { Container, FAQs, LogoClouds, NewsLetter } from '@/components';
import { faqQuestion } from '@/components/FAQ/faq.utils';
import { useDepositsLayout } from '@/layouts/DepositsLayout/useDepositsLayout';
import { HomeProducts } from '@/layouts/HomeLayoutV2/parts/HomeProducts';
import { MoreOnWebsite } from '@/layouts/HomeLayoutV2/parts/MoreOnWebsite';
import { SavingsPlatform } from '@/layouts/HomeLayoutV2/parts/SavingsPlatform';
import background from '@/public/assets/hero-updated/background.png';
import { useAuthModalsControl, useAuthStore } from '@/store';
import { cn, decodeBase64 } from '@/utils';

export interface IHomeProps {
  className?: string;
}

export const HomeLayoutV2: FC<IHomeProps> = ({ className }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { token, setEmail, setIsCodeLink, setVerificationCode } = useAuthStore(
    (state) => state,
  );

  const openLoginModal = useAuthModalsControl((state) => state.openLoginModal);
  const { syncUrlWithStore } = useDepositsLayout();

  useEffect(() => {
    syncUrlWithStore();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const { code } = router.query;
      if (code && !token) {
        setIsCodeLink(true);

        const decodedCodeString = decodeBase64(code as string);

        if (!decodedCodeString) return;

        const decodedObj = JSON.parse(decodedCodeString);

        setVerificationCode(decodedObj.code);
        setEmail(decodedObj.email);

        openLoginModal();
      }
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={cn('z-10 flex h-full flex-col rounded-lg', className)}>
      <div className="bg-blue-100 pt-6 sm:pt-12">
        <div className="relative mx-auto max-w-[700px]">
          <div className="absolute flex w-full flex-col items-center gap-2 px-4 text-center sm:px-0">
            <h1 className="h1">
              <Trans
                i18nKey="homev2.h1"
                t={t}
                components={{
                  blueText: <span className="text-blue-500" />,
                }}
              />
            </h1>
            <p className="body1 text-grey-800">
              <Trans
                i18nKey="homev2.subtitle"
                t={t}
                components={{
                  boldText: <span className="body1-bold text-blue-900" />,
                }}
              />
            </p>
          </div>
          <div className="flex w-full items-center justify-center pt-36 sm:pt-0">
            <Image src={background} alt="background" width={716} height={500} />
          </div>
        </div>
      </div>

      <div className="mt-7 flex w-full border-b">
        <Container className="z-[1]">
          <LogoClouds />
        </Container>
      </div>

      <HomeProducts />

      <SavingsPlatform />

      <MoreOnWebsite />

      {/* <div className="w-full xl:mb-5 xl:px-4"> */}
      {/*  <div className="w-full bg-blue-50 pt-16 xl:rounded-2xl"> */}
      {/*    <PickDeposits deposits={topDeposits} /> */}
      {/*  </div> */}
      {/* </div> */}

      <div className="w-full xl:px-4">
        <div className="w-full bg-blue-50 px-4 pb-8 pt-4 md:pb-[57px] lg:mb-4 lg:pb-[30px] xl:rounded-2xl xl:pt-[64px]">
          <FAQs
            questions={faqQuestion}
            blockClassName="body2 md:body1 lg:min-h-[96px]"
          />
        </div>
      </div>

      <NewsLetter />
    </div>
  );
};
